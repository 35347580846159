.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: attr(data-before);
  display: inline-block;
  outline: none;
  padding-left: 0.4rem;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 300;
}
.checkOption {
  align-items: center;
  position: absolute;
  height: 55px !important;
  /* left: 720px; */
  /* right: 0px; */
  top:100px!important;

}
.btn {
  height: 35px !important;
}
.ListaEventosModal {
  align-items: center;
  min-width: 30px;
  border-radius: 10px;
  min-width: 117px;
  width:117px; 
  height:93px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 0, 0);
  object-fit: cover !important;
  overflow: hidden !important;
}
.rdt_TableRow {
  min-height: 70px !important;
  cursor: pointer;
  overflow: hidden;
}

.tagLabel {
  background-color: rgb(210, 210, 210);
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 3px;
  margin-bottom: 2.5px;
  padding-top: 4px;
  padding-bottom: 2px;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
}

.link {
  text-decoration: underline;
  color: rgb(73, 161, 255);
}
.inputRename{
  height: 27px;
  margin-top: 3px;
}
.data-table-extensions-filter {
  position: relative;
}
.data-table-extensions-filter label{
  position:absolute;
  height:30px!important;
  margin-left: 10px;
  margin-top: 5px;
}

.data-table-extensions-filter input {
  width: 250px;
  margin-top: 1px;
  height: 39px;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb ;
  text-indent: 10px;
  padding-left: 25px!important;
  margin-left: 0!important;
  background-color: rgb(255, 255, 255) !important;
}

.data-table-extensions{
  padding-left: 1px!important;
}

.data-table-extensions > .data-table-extensions-filter > .icon {
  background-image: url(../../images/common/magnifying-glass.svg)!important;
  
}
.css-yk16xz-control{
  align-items: center;
  overflow-y: visible;
}
#contentListTable > div > div > .rdt_Table{
  overflow-x: invisible;
  max-height: 65vh !important;
}
.rdt_Pagination{
  padding-right: 2% !important;
}

.sc-iTFTee.react-trello-lane ::-webkit-scrollbar{
  width: 5px ;
}
.sc-iTFTee.react-trello-lane ::-webkit-scrollbar-thumb {
  background: rgb(179, 179, 179); 
  border-radius: 25px;
}


.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	z-index: -1;
}

.inputfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
}

.inputfile:focus + label,y
.inputfile + label:hover {
  background-color: red;
  cursor: pointer;

}
.inputLabel{
  font-size: 0.875rem;
  display: none;
  opacity: 0;
  transition: visibilty 0.3s, opacity 0.3s;
}

#btnBrowseFile:hover .inputLabel{
    display: inline;
    opacity: 1;
}
