.btnUser{
    /* color:white; */
    position: absolute;
    display: flex;
    justify-content:center;
    text-align: center;
    padding-left:23.5px;
    left:0px;
    top:90vh;
    
    z-index:22323;
    font-size:30px;
    /* background-color:rgb(186, 228, 243); */
    border-radius:100%;
    width:50px;
    height: 50px;
}

.list{
    /* background-color: rgba(200, 241, 255, 0.3); */
    border-radius: 100%;
    padding:14px;
    position: absolute;
    left: 0rem;
    bottom:0rem;
    z-index: 10;
    color:red;
}
.listDad{
    border-radius: 10px;
    background-color:rgba(255, 255, 255, 0.863);
    left:1.5rem;
    bottom: -1.5rem;
    width:125px;
}