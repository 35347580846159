

.slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes slideInRight {
    0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: visible;
    }
    100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    }
    }
    @keyframes slideInRight {
    0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: visible;
    }
    100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    }
    } 
    .blackScreen{
        background-color:rgba(0, 0, 0, 0.171);
        display: flex;
        flex-direction: row-reverse;
        visibility: none;

    }
    
  