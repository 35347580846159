.fc-daygrid-event-harness *{
    border: 0px;
}

.labelChangeName {
    /* margin-right:20px; */
    display: flex;
    align-items: center;
    
}
.labelChangeName input{
    margin-right:5px;
    height:25px;
    padding-top: 0px;
    border-radius: 4px;
}
.iconRename{
    margin-top: 6px;
}

.decoration-blackk{
font-weight: bold;
}
.toltip {
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 20px;
}
.toltip:hover{
    visibility: block;
}

.tooltipIndex{
    z-index: 40!important;
}
/* .fc-daygrid-day-events{
    position: absolute;
    z-index: 20!important;
} */
.fc-col-header-cell{
    background-color: #FFF;
    color:#888!important;
    font-weight: 300;
}
.fc-button{
    background-color: #93c5fd!important;
    height:2.5rem;
    color:#ffffff!important;
    text-align: center;
    font-weight: bold!important;
    margin-bottom:10px;
    border: 1px solid #f3f4f6!important;
    letter-spacing: .05rem;

}
.screenZone{
    max-width: 17rem;
    max-height:20rem;
}
.fc-button:hover{
    background-color: #73aff3!important;
}
.fc-button-active{
    background-color: #73aff3!important;
    color:red;
    border: none;
}
.fc-button-active:focus{
    background-color: #73aff3!important;
}
.fc .fc-timegrid-slot-label-cushion{
    background-color: #d1d5db!important;
}

.slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
    -webkit-animation-duration: 0.15s;
    animation-duration: 0.15s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes slideInLeft {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes slideInLeft {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
} 

.closeBtn{
    font-size: 20px;
    font-weight: bold;
    display: none;
}

.pinocho{
    position: fixed!important;
    z-index: 2000!important;
}

   
@media (max-width: 1000px) {
    .calendarHeader{
        display:flex;
        flex-direction: reverse;
    }
    .dateBox{
        display:flex;
        flex-direction: column;
    }
    .dateBox label{
        width: 100%;
    }
    .fc .fc-toolbar.fc-header-toolbar {
        margin-bottom: 0em;
    }
    .screenZone{
        max-width: 100%;
    } 
    .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
        margin-left: 0;
    }
    .searchBar{
        margin-right: 0!important;
    }
    .closeBtn{
        display: block
    }
    .planContentEvent{
        display: none;
    }
    .searchBarEvent{
        justify-content:center;
    }
    .calendarHeader{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .fc-toolbar-chunk{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between!important;
    }
   
    .modalContent{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-left:3rem;
        padding-top:1rem;
    }
    .modal-body {
        padding-left:1.4rem;
    }
    .textStepFor{
        flex-wrap: wrap!important;
    }
    .menuScreen{
        margin-top:-6.5rem ;
        padding-top: 3rem;
        padding-bottom: 15rem;
        left:20px;
        padding-left:4rem;
        right:50%;  
        z-index: 11;
        position: absolute;
        width: 100%
    }
    .displayNone{
        display: none;
    }
    .fc-header-toolbar {
        display: flex!important;
        flex-direction: column;
        
    }
    .fc-toolbar-chunk{
        display: flex!important;
        flex-direction: row;
        flex-direction:row-reverse;
    }
    .fc-toolbar-title{
        
        font-size:15px!important;
    }
    .calendar{
        height:100%;
        margin-left: 0!important;
        margin-right: 1rem!important;
        padding-left: 0!important;
    }
    .calendarDad{
        width: 100%!important;
    }
    .btnOptionsScreen{
        display: block;
        margin-bottom:10px;
        margin-left: 10px;
        font-size: 25px;
    }
  }
  .textStepFor {
    min-width:100px;
    max-width:600px;
  }
 
  .modalWidth{
    min-width:500px;
 
  }

  .stepOne{
      width: 900px;
  }
  

  /* @media (max-width: 1900px) {
    .btnsModal{
        flex-wrap: wrap!important;
        justify-content:end!important;
     }
  }


  @media (max-width: 1000px) {
    .btnsModal{
        flex-wrap: nowrap!important;
        justify-content:space-between!important;
     }
     .modalWidth{
        min-width:50px;
        margin-left: 50px;
        overflow-x: auto;
      }
      
  } */