.screenEvent{
    /* background-color: rgb(206, 206, 206); */
    width:200px;
    /* background-color:red; */
    display:flex;
    justify-content:center;
    align-items: center!important;
    /* max-width: 11rem; */
    height: 100px !important;
    /* height:20px; */
    /* display:flex;
    justify-content:center; */
    object-fit: cover!important;
    /* margin-bottom:0!important;
    margin-right: 10px;
    border-radius: 15px!important;
    margin-top:8px */

}
.spanBadge {
    /* position: absolute; */
    /* transform: translateX(5%); */
}
.contenedorEvento{
    
    /* margin-left: 10px;  */
    min-width: 117px!important;
    width:117px!important; 
    height:80px!important;
    align-items: center;
    /* display:flex;
    align-items: center;
    justify-content:center;
    background-color:rgb(0, 0, 0);
    overflow:hidden!important; */
    /* object-fit: cover!important; */
}
.screenFont{
    font-size:15px;
    font-weight:bold;
    /* color:rgb(9, 176, 247) */
}
.font-lightt{
        font-size: 12px;
        font-weight: 500;
   
}
.iconCircleStar{
    margin-bottom:2.5px;
    margin-left:2px;
    /* color:rgb(255, 174, 22); */
    /* font-size:14px; */
}
.icon{
    color:rgb(36, 171, 255)
}

.title{
    margin-left: 4px;
    margin-bottom:1px;
    margin-top:5px;
}

.containerContentEvent{
    max-width: 100px;
    background-color:rgb(0, 0, 0);
    height:100px;
    margin: 5px;    
    display:flex;
    align-items:center;
    object-fit: cover!important;

}
.containerContentSpan{
    /* background-color:rgb(255, 32, 32); */
    height:100px;
}