body {
    overflow: hidden; /* Hide scrollbars */
  }
.dragOverEffect {
    box-shadow: -3px -3px 0 0 blue;
}
.rdt_Pagination > div > svg {display:none !important;}

.custom-max-w-quarter {
    max-width: 75% !important;
}

.custom-max-w-quarter > div > div > .rdt_Table {
    max-height: 85vh !important;
}

.custom-min-h-xs {
    min-height: 1rem !important;
}

.custom-min-h-m {
    min-height: 26rem !important;
}

.custom-max-h-25 {
    max-height: 25%;
}

.custom-max-h-90 {
    max-height: 90%;
}
.custom-h-m {
    height: 21rem !important;
}
.custom-max-h-60 {
    max-height: 60%;
}
.messageErrorBody{
    height: 400px;
}
.snap-container{
    scroll-snap-type: y mandatory;
}
.snap-children{
    scroll-snap-align: center;
}

.scroll-gradient {
    height: 100vh; /* o la altura deseada para el contenedor */
    overflow-y: scroll;
    mask-image: linear-gradient(to bottom, transparent, black 20px, black calc(100% - 20px), transparent),
                linear-gradient(to top, transparent, black 20px, black calc(100% - 20px), transparent);
    mask-size: 100% 40px; /* o la altura deseada para el gradiente */
    mask-repeat: no-repeat;
  }

  .modalCamera{
    width: 470px;
    height: 420px;
  }
